import { useCallback, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';
import {
  getFirstFullySelectedGroup,
  getInactiveItemsValues,
  getItemsValues,
  getListItemsFlat,
  toggleMultiSelectValue,
  toggleSingleSelectValue,
} from './utils';

export type ToggleValueFn = (selectedValuesInput: string[]) => void;

const sortStrings = (a: string, b: string) => a.localeCompare(b);

export type UseDropdownProps = {
  multiSelect?: boolean;
  onChange: ToggleValueFn;
  items: ListDropdownItem[];
  placeholder: string;
  selectedValues: string[];
  disabled: boolean;
  autoSelect?: 'first' | 'all';
};

export const useDropdownValues = (props: UseDropdownProps) => {
  const [selectedItems, _setSelectedItems] = useState<string[]>([...props.selectedValues]);
  const deferredSelectedItems = useDeferredValue(selectedItems);
  const hasSetInitalItems = useRef<boolean>(false);
  const selectedItemsSet = useMemo(() => new Set(selectedItems), [selectedItems]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const { t } = useTranslation();
  const propsOnChange = props.onChange;

  const inactiveValues = useMemo(() => getInactiveItemsValues(props.items), [props.items]);

  const setSelectedItems = useCallback(
    (inputSelectedItems: string[]) => {
      // Remove inactive values
      const filteredValues = inputSelectedItems.filter((value) => !inactiveValues.includes(value));

      _setSelectedItems(filteredValues);
      propsOnChange(filteredValues);
    },
    [inactiveValues, propsOnChange],
  );

  useEffect(() => {
    // Remove selected items that are no longer in the list
    const availableValues = getItemsValues(props.items);
    const filteredValues = selectedItems.filter((value) => availableValues.includes(value));

    const filteredValuesCompare = filteredValues.sort(sortStrings).join(',');
    const selectedValuesCompare = Array.from(selectedItems).sort(sortStrings).join(',');

    if (
      filteredValuesCompare.length > 0 &&
      filteredValuesCompare !== selectedValuesCompare &&
      props.items.length
    ) {
      setSelectedItems(filteredValues);
    }
  }, [props.items, selectedItems, setSelectedItems]);

  useEffect(() => {
    if (
      props.autoSelect !== 'first' ||
      props.items.length === 0 ||
      props.selectedValues.length ||
      hasSetInitalItems.current
    ) {
      return;
    }

    const firstLocation = props.items.find((item) => {
      const itemValues = getItemsValues([item]);
      const itemSelectableValues = itemValues.filter((value) => !inactiveValues.includes(value));

      return itemSelectableValues.length > 0;
    });

    if (firstLocation) {
      const preselectedValues = getItemsValues([firstLocation]);

      setSelectedItems(preselectedValues);
      hasSetInitalItems.current = true;
    }
  }, [inactiveValues, props.autoSelect, props.items, props.selectedValues, setSelectedItems]);

  useEffect(() => {
    if (
      props.autoSelect !== 'all' ||
      props.items.length === 0 ||
      props.selectedValues.length ||
      hasSetInitalItems.current
    ) {
      return;
    }

    const allLocations = props.items;
    const preselectedValues = getItemsValues(allLocations);

    if (preselectedValues.length) {
      setSelectedItems(preselectedValues);
      hasSetInitalItems.current = true;
    }
  }, [props.autoSelect, props.items, props.selectedValues, setSelectedItems]);

  useEffect(() => {
    // Remove inactive values
    const filteredValues = selectedItems.filter((value) => !inactiveValues.includes(value));

    // if not the same, update
    if (filteredValues.join(',') !== selectedItems.join(',')) {
      _setSelectedItems(filteredValues);
      propsOnChange(filteredValues);
    }
  }, [inactiveValues, propsOnChange, selectedItems]);

  useEffect(() => {
    const propsItemsSpread = [...props.selectedValues];
    const propsItems = propsItemsSpread.sort(sortStrings);
    const stateItems = [...selectedItems].sort(sortStrings);

    // Deep-compare the selected values from props and state
    if (propsItems.join(',') !== stateItems.join(',')) {
      _setSelectedItems(propsItemsSpread);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only run if prop changes
  }, [props.selectedValues]);

  const toggleValues: ToggleValueFn = (toggleValues) => {
    if (props.multiSelect) {
      const newItems = toggleMultiSelectValue(selectedItems, toggleValues);

      setSelectedItems(newItems);
    } else {
      const newItems = toggleSingleSelectValue(selectedItems, toggleValues[0]);

      setSelectedItems(newItems);
    }
  };

  const isItemSelected = (item: ListDropdownItem, hideInactive?: boolean) => {
    if (item.type === 'value') {
      if (item.children.length) return isGroupSelected(item, hideInactive);

      return isValueSelected(item.value);
    } else if (item.type === 'group') {
      return isGroupSelected(item, hideInactive);
    }
  };

  const isValueSelected = (value: string) => {
    return selectedItemsSet.has(value);
  };

  const isAllSelected = (items: ListDropdownItem[], filterInactive?: boolean) => {
    const values = getItemsValues(items);

    if (filterInactive) {
      const inactiveValues = getInactiveItemsValues(items);

      const filteredValues = values.filter((value) => !inactiveValues.includes(value));

      return filteredValues.every((value) => isValueSelected(value));
    }

    return values.every((value) => isValueSelected(value));
  };

  const isSomeSelected = (items: ListDropdownItem[]) => {
    const values = getItemsValues(items);

    return values.some((value) => isValueSelected(value));
  };

  const toggleSelectAll = (items: ListDropdownItem[]) => {
    const allSelected = isAllSelected(items, true);

    if (allSelected) {
      setSelectedItems([]);
    } else {
      const values = getItemsValues(items);

      setSelectedItems(values);
    }
  };

  const toggleItem = (item: ListDropdownItem) => {
    //If we are toggling values, we are past the initial set of items
    hasSetInitalItems.current = true;
    if (!props.multiSelect) {
      singleSelectToggleItem(item);

      return;
    }

    if (item.type === 'group') toggleGroup(item);
    if (item.type === 'value') {
      if (item.children.length) toggleGroup(item);
      else toggleValues([item.value]);
    }
  };

  const singleSelectToggleItem = (item: ListDropdownItem) => {
    setSelectedItems([item.value]);
    // toggleValues([item.value]); is this needed?
  };

  const toggleGroup = (group: ListDropdownItem) => {
    const isGroupSelected = isSomeSelected([group]);

    const values = getItemsValues([group]);

    if (isGroupSelected) {
      // Remove items
      setSelectedItems(selectedItems.filter((value) => !values.includes(value)));
    } else {
      // Add items
      setSelectedItems([...new Set([...selectedItems, ...values])]);
    }
  };

  const isGroupSelected = (
    group: ListDropdownItem,
    hideInactive?: boolean,
  ): boolean | 'partial' => {
    const values = getItemsValues([group]);

    if (values.length === 0) return false;

    const isSelected = group.type === 'value' && isValueSelected(group.value);

    if (isSelected) return true;

    const allSelected = isAllSelected([group], hideInactive);

    if (allSelected) return true;

    const someSelected = isSomeSelected([group]);

    if (someSelected) return 'partial';

    return false;
  };

  const hasSelectedItems = selectedItems.length > 0;

  const dropdownText = useMemo(() => {
    if (selectedItems.length === 0 || props.items.length === 0) {
      return props.placeholder;
    }

    const allSelected = isAllSelected(props.items, true);

    if (allSelected && selectedItems.length > 1) {
      setAllSelected(true);

      return t('components.dropdown.all');
    } else {
      setAllSelected(false);
    }

    const selectedGroup = getFirstFullySelectedGroup(props.items, selectedItems);

    if (selectedGroup?.label.length) {
      return selectedGroup.altLabel ?? selectedGroup.label;
    } else {
      const selectedListItems = getListItemsFlat(props.items, selectedItems);
      const selectedLabel = selectedListItems[0]?.altLabel ?? selectedListItems[0]?.label;

      return selectedLabel;
    }
  }, [props.items, props.placeholder, selectedItems]);

  return {
    selectedItems: deferredSelectedItems,
    toggleValues,
    isItemSelected,
    hasSelectedItems,
    toggleSelectAll,
    dropdownText,
    additionalSelectedAmount: selectedItemsSet.size - 1,
    isAllSelected,
    toggleItem,
    allSelected,
  };
};
